<template>
  <div class="yjr-roomordercontant">
    <div class="page_title">教室预约审核</div>
    <div class="toolsbar">
      <div class="roomcontant">
        <p>教室：</p>
        <el-select v-model="selectvalue" placeholder="请选择教室" @change="selectchange">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="datecontant">
        <p>使用日期：</p>
        <el-date-picker v-model="usingdate" type="date" placeholder="选择日期" @change="selectchange" format="yyyy-M-d" value-format="yyyy-M-d"> </el-date-picker>
      </div>
      <div>
        <el-button type="primary" @click="managerorderroom">管理员锁定教室</el-button>
      </div>
    </div>
    <el-table :data="roomorderlist" style="width: 100%" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" show-scrollbar="true">
      <el-table-column prop="user.user_info.user_name" width="150" label="姓名"> </el-table-column>
      <el-table-column prop="user.user_info.classroom" width="200" label="班级"> </el-table-column>
      <el-table-column prop="room_id" label="借用教室" width="90"> </el-table-column>
      <el-table-column prop="using_date" label="使用日期" width="120"> </el-table-column>
      <el-table-column prop="using_time" label="使用时间" width="120"> </el-table-column>
      <el-table-column label="是否使用多媒体" width="120">
        <template slot-scope="scope">
          {{ scope.row.isusingmulti_media == 0 ? '否' : '是' }}
        </template>
      </el-table-column>
      <el-table-column prop="usage_and_peoplenum" label="用途及人数"> </el-table-column>
      <el-table-column
        prop="isaccessed"
        label="标签"
        width="120"
        :filters="[
          { text: '已拒绝', value: 2 },
          { text: '已通过', value: 1 },
          { text: '待审核', value: 0 }
        ]"
        :filter-method="filterTag"
        filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.isaccessed === 2 ? 'danger' : scope.row.isaccessed === 1 ? 'success' : 'info'" disable-transitions>{{ scope.row.isaccessed === 2 ? '已拒绝' : scope.row.isaccessed === 1 ? '已通过' : '待审核' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleView(scope.$index, scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="paginationcontant">
      <el-pagination :hide-on-single-page="true" :current-page.sync="currentpage" :page-size="pagesize" layout="prev, pager, next" :total="totaldatanum" @current-change="pagechange"> </el-pagination>
    </div>
    <el-dialog :append-to-body="true" title="教室预约详情" :visible.sync="centerDialogVisible" width="30%" center>
      <span v-if="detailorder">
        <div style="margin: 10px">姓名：{{ detailorder.user.user_info.user_name }}</div>
        <div style="margin: 10px">学号：{{ detailorder.user.uid }}</div>
        <div style="margin: 10px">班级：{{ detailorder.user.user_info.classroom }}</div>
        <div style="margin: 10px">借用人联系方式{{ detailorder.phone_number }}</div>
        <div style="margin: 10px">借用教室：{{ detailorder.room_id }}</div>
        <div style="margin: 10px">使用时间：{{ detailorder.using_date + ' ' + detailorder.using_time }}</div>
        <div style="margin: 10px">是否使用多媒体：{{ detailorder.isusingmulti_media == 0 ? '否' : '是' }}</div>
        <div style="margin: 10px">用途及人数：{{ detailorder.usage_and_peoplenum }}</div>
        <div style="margin: 10px">提交时间：{{ detailorder.create_time }}</div>
      </span>
      <span v-if="detailorder" slot="footer" class="dialog-footer">
        <div v-if="detailorder.isaccessed == 0">
          <el-button type="primary" @click="clickbtn(1)">同意</el-button>
          <el-button type="danger" @click="clickbtn(2)">拒绝</el-button>
        </div>
        <div v-else :style="detailorder.isaccessed == 1 ? 'font-size:24px;height:40px;background-color: #f0f9eb;color: #67c23a;' : 'font-size:24px;height:40px;background-color: #fef0f0;color: #f76c6c;'">{{ detailorder.isaccessed == 1 ? '审核通过' : '审核不通过' }}</div>
      </span>
    </el-dialog>
    <el-dialog :append-to-body="true" title="管理员锁定教室" :visible.sync="manageorderDialogVisible" width="30%" center>
      <span>
        <p>教室：</p>
        <el-select v-model="manageorderselectvalue" placeholder="请选择教室">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <p>使用日期：</p>
        <el-date-picker v-model="manageorderusingdate" type="date" placeholder="选择日期" format="yyyy-M-d" value-format="yyyy-M-d"> </el-date-picker>
        <p>使用时间段</p>
        <el-checkbox-group v-model="checkedtimes">
          <el-checkbox v-for="time in canbeordered_timeoptions.slice(1)" :label="time.label" :key="time.label">{{ time.label }}</el-checkbox>
        </el-checkbox-group>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="managerorderroom">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'yjr-roomorderpage',
  data() {
    return {
      roomorderlist: [],
      options: [
        {
          value: 0,
          label: '全部'
        }
      ],
      canbeordered_timeoptions: [
        {
          value: 0,
          label: '全部'
        }
      ],
      checkedtimes: [],
      selectvalue: 0,
      usingdate: '',
      detailorder: null,
      centerDialogVisible: false,
      manageorderDialogVisible: false,
      manageorderselectvalue: 0,
      manageorderusingdate: '',
      manageorderusingtime: '',
      totaldatanum: 100,
      pagesize: 10,
      currentpage: 1
    }
  },
  created() {
    this.getsettings()
    this.getallroomorder()
  },
  methods: {
    async getsettings() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data } = await this.$http.get('/getsettings').catch((result) => {
        this.$message.error('网络错误')
      })
      loading.close()
      if (data.code !== 0) {
        this.$message.error(data.msg)
      } else {
        console.debug(data.data.roomorder_choices)
        const op = data.data.roomorder_choices.map(function (item, index) {
          return { value: index + 1, label: item.value }
        })
        this.options.push(...op)
        this.canbeordered_time = data.data.canbeordered_time
        this.canbeordered_timeoptions.push(
          ...data.data.canbeordered_time.map(function (item, index) {
            return { value: index + 1, label: item }
          })
        )
      }
    },
    selectchange(e) {
      // 选择教室和时间后刷新页面操作
      this.currentpage = 1
      this.getallroomorder()
    },
    pagechange(e) {
      // 获取下一页数据
      this.getallroomorder()
    },
    filterTag(value, row) {
      return row.isaccessed === value
    },
    async clickbtn(i) {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data } = await this.$http.post('/manager/make_roomordercheck', { orderid: this.detailorder.id, isaccessed: i }).catch((result) => {
        this.$message.error('网络错误')
      })
      loading.close()
      if (data.code !== 0) {
        this.$message.error(data.msg)
      } else {
        this.$message({
          showClose: true,
          message: '操作成功',
          type: 'success'
        })
      }
      this.getallroomorder()
      this.centerDialogVisible = false
    },
    async getallroomorder() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formdata = {
        classroom: this.selectvalue === 0 ? '' : this.options[this.selectvalue].label,
        using_date: this.usingdate,
        currentpage: this.currentpage,
        pagesize: this.pagesize
      }
      const { data } = await this.$http.post('/manager/get_roomorder', formdata).catch((result) => {
        this.$message.error('网络错误')
      })
      loading.close()
      if (data.code !== 0) {
        this.$message.error(data.msg)
      } else {
        console.debug(data.data)
        this.roomorderlist = data.data
        this.totaldatanum = data.totaldatanum
      }
    },
    handleView(i, r) {
      this.detailorder = r
      this.centerDialogVisible = true
    },
    managerorderroom() {
      if (!this.manageorderDialogVisible) {
        this.manageorderDialogVisible = true
      } else {
        if (!this.manageorderselectvalue || !this.manageorderusingdate) {
          this.$message.error('请选择需要锁定的教室和时间')
        } else if (this.manageorderselectvalue !== this.selectvalue || this.manageorderusingdate !== this.usingdate) {
          this.$alert('请先查询锁定时间段是否有教室借用信息', '警告', {
            confirmButtonText: '确定',
            callback: (action) => {
              this.selectvalue = this.manageorderselectvalue
              this.usingdate = this.manageorderusingdate
              this.currentpage = 1
              this.getallroomorder()
            }
          })
          this.manageorderDialogVisible = false
        } else {
          if (this.checkedtimes.length === 0) {
            this.$message.error('请选择需要锁定的时间段')
            return
          }

          this.$confirm('此操作将锁定此时间段教室的借用权限,已经通过的教室借用将被改为拒绝，请通知已借用教室的学生, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              // 发送请求锁定教室

              const loading = this.$loading({
                lock: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              })
              const formdata = {
                room_id: this.options[this.manageorderselectvalue].label,
                using_date: this.manageorderusingdate,
                using_time: this.checkedtimes
              }
              this.$http
                .post('/manager/lock_roomorder', formdata)
                .then((res) => {
                  const data = res.data
                  loading.close()
                  if (data.code !== 0) {
                    this.$message.error(data.msg)
                  } else {
                    console.debug(data.data)
                    this.$message({
                      type: 'success',
                      message: '锁定成功!'
                    })
                    this.currentpage = 1
                    this.getallroomorder()
                    this.manageorderDialogVisible = false
                  }
                })
                .catch((result) => {
                  this.$message.error('网络错误')
                })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '取消操作'
              })
              this.manageorderDialogVisible = false
            })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.yjr-roomordercontant {
  min-height: 100%;
  width: 100%;
  background-color: #f5f6fa;
  position: absolute;
  padding: 20px 20px;
  .page_title {
    position: relative;
    width: 130px;
    height: 25px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25px;
    color: rgba(40, 45, 48, 1);
    text-align: left;
    margin-bottom: 25px;
  }
  .toolsbar {
    width: 100%;
    position: relative;
    height: 75px;
    opacity: 1;
    border-radius: 5px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 5px 30px;
    margin-bottom: 10px;
    .roomcontant,
    .datecontant {
      display: flex;
      flex-direction: row;
      width: 400px;
      height: 45px;
      padding: 7px 0;
      align-items: center;
      justify-content: start;
      margin-left: 20px;
      p {
        height: 100%;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 31px;
        color: rgba(40, 45, 48, 1);
        text-align: right;
      }
      /deep/ .el-select .el-input__inner {
        font-size: 12px !important;
        font-weight: 400;
      }
      .inputarea {
        width: 200px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
      }
    }
    .roomcontant {
      margin-left: 0;
    }
    .btncontant {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .el-button {
        width: 80px;
        height: 38px;
        padding: 0;
        font-size: 12px;
        font-weight: 400;
      }
      div {
        display: flex;
        flex-direction: row;
      }
    }
  }
  .paginationcontant {
    height: 50px;
    width: 100%;
    padding: 8px 5px;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: white;
  }
  /deep/ .my-dialog {
    z-index: 9999; /* 自定义对话框的层级 */
  }
  .mylableactive {
    font: 400;
    background-color: #f0f9eb;

    color: #67c23a;
  }
  .mylabledisactive {
    font: 400;
    background-color: #fef0f0;
    color: #f76c6c;
  }
}
</style>
