import { render, staticRenderFns } from "./Createactivity.vue?vue&type=template&id=f21a183a&scoped=true&"
import script from "./Createactivity.vue?vue&type=script&lang=js&"
export * from "./Createactivity.vue?vue&type=script&lang=js&"
import style0 from "./Createactivity.vue?vue&type=style&index=0&id=f21a183a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f21a183a",
  null
  
)

export default component.exports