import { render, staticRenderFns } from "./Mainpage.vue?vue&type=template&id=8c26e686&scoped=true&"
import script from "./Mainpage.vue?vue&type=script&lang=js&"
export * from "./Mainpage.vue?vue&type=script&lang=js&"
import style0 from "./Mainpage.vue?vue&type=style&index=0&id=8c26e686&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c26e686",
  null
  
)

export default component.exports