<template>
  <div class="yjr-mainpagecontant">
    <div class="allstatistic">
      <div class="itemcontant" v-for="(value, key) in statisticdata" :key="key">
        <div class="itemtitle">{{ value.label }}</div>
        <div class="itemcount">
          <div class="iconcontant">
            <img class="icon" v-if="key === 'activate'" src="../assets/clickrate.png" alt="" />
            <img class="icon" v-else-if="key === 'total'" src="../assets/invitessent.png" alt="" />
            <img class="icon" v-else-if="key === 'finished'" src="../assets/responserate.png" alt="" />
            <img class="icon" v-else-if="key === 'uncheked_room' || key === 'uncheked_credit'" src="../assets/reviews.png" alt="" />
          </div>
          <div class="countnum">{{ value.count }} 个</div>
        </div>
      </div>
    </div>
    <div class="activitycontant">
      <div class="ctrlbar">
        <div class="leftcontant">
          <p class="title">活动数量</p>
          <div class="statucontant">
            <el-radio-group v-model="filteractivity.radio" @input="changeradio">
              <el-radio :label="0" style="margin-bottom: 0; font-size: 12px">本科生</el-radio>
              <el-radio :label="1" style="margin-bottom: 0; font-size: 12px">研究生</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="rightcontant">
          <div class="datecontant">
            <el-date-picker v-model="filteractivity.date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :clearable="false" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :editable="false" @blur="filteractivitychosedate"> </el-date-picker>
          </div>
          <div class="checkcontant">
            <p>统计方式：</p>
            <el-select v-model="filteractivity.selectedvalue" placeholder="请选择统计类型" @change="filteractivityselectchange"> <el-option v-for="item in filteractivity.options" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
          </div>
          <div class="searchcontant">
            <el-input :disabled="filteractivity.selectedvalue === 0" class="custom-input" placeholder="请输入学生学号" v-model="filteractivity.inputuid" clearable>
              <el-button :disabled="filteractivity.selectedvalue === 0" type="primary" slot="append" icon="el-icon-search" @click="filteractivityinputsearch"></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div class="tablecontant" id="filteractivity" style="width: 100%; height: 450px"></div>
    </div>
    <div class="stydutimecontant">
      <div class="ctrlbar">
        <div class="leftcontant">
          <p class="title">自习时长</p>
        </div>
        <div class="rightcontant">
          <div class="datecontant">
            <el-date-picker v-model="selfstudy.date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :clearable="false" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :editable="false" @blur="chosedate"> </el-date-picker>
          </div>
          <div class="checkcontant">
            <p>统计方式：</p>
            <el-select v-model="selfstudy.selectedvalue" placeholder="请选择统计类型" @change="selectchange"> <el-option v-for="item in selfstudy.options" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
          </div>
          <div class="searchcontant">
            <el-input :disabled="selfstudy.selectedvalue === 0" class="custom-input" placeholder="请输入学生学号" v-model="selfstudy.inputuid" clearable>
              <el-button :disabled="selfstudy.selectedvalue === 0" type="primary" slot="append" icon="el-icon-search" @click="inputsearch"></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div class="tablecontant" id="selfstudytime" style="width: 100%; height: 450px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'yjr-mainpage',
  data() {
    return {
      selfstudy: {
        date: [],
        inputuid: '',
        selectedvalue: 0,
        options: [
          {
            value: 0,
            label: '全部'
          },
          {
            value: 1,
            label: '年级'
          },
          {
            value: 2,
            label: '班级'
          },
          {
            value: 3,
            label: '寝室'
          },
          {
            value: 4,
            label: '个人'
          }
        ],
        tabledata: {
          xlable: [],
          y: []
        }
      },
      filteractivity: {
        date: [],
        inputuid: '',
        selectedvalue: 0,
        radio: 0,
        options: [
          {
            value: 0,
            label: '全部'
          },
          {
            value: 1,
            label: '年级'
          },
          {
            value: 2,
            label: '班级'
          },
          {
            value: 3,
            label: '寝室'
          },
          {
            value: 4,
            label: '个人'
          }
        ],
        tabledata: {
          xlable: [],
          y1: [],
          y2: []
        }
      },
      statisticdata: {}
    }
  },
  created() {
    // 获取今年的第一天
    const year = new Date().getFullYear()
    const firstDayOfYear = new Date(year, 0, 1)
    // 获取当前日期
    const currentDate = new Date()
    this.selfstudy.date = [this.formatDateTime(firstDayOfYear, 'yyyy-MM-dd'), this.formatDateTime(currentDate, 'yyyy-MM-dd')]
    this.filteractivity.date = [this.formatDateTime(firstDayOfYear, 'yyyy-MM-dd'), this.formatDateTime(currentDate, 'yyyy-MM-dd')]
    this.getallactivitydata()
    this.getfilterstudytime()
    this.getfilteractivity()
  },
  updated() {
    // 页面更新后的操作
    // ...
    this.showactivitytable()
    this.showselfstudytable()
  },
  methods: {
    // 日期格式化
    formatDateTime(date, format) {
      const o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
        'H+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
        a: date.getHours() < 12 ? '上午' : '下午', // 上午/下午
        A: date.getHours() < 12 ? 'AM' : 'PM' // AM/PM
      }
      if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (const k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
          format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
        }
      }
      return format
    },
    // 自习时间筛选工具函数
    selectchange(e) {
      this.selfstudy.inputuid = ''
      if (e === 0) {
        this.getfilterstudytime()
      }
    },
    inputsearch() {
      if (this.selfstudy.selectedvalue === 0) {
        this.$message.error('此统计方式禁止搜索')
      } else if (this.selfstudy.inputuid === '') {
        this.$message.warning('您需要先输入些什么')
      } else if (this.selfstudy.date.length !== 2) {
        this.$message.warning('请选择日期范围')
      } else {
        this.getfilterstudytime()
      }
    },
    chosedate() {
      console.log('日期选择结束')
      console.log(this.selfstudy.date)
      if (this.selfstudy.selectedvalue === 0) {
        this.getfilterstudytime()
      }
    },
    // 活动数目筛选工具函数
    changeradio() {
      this.getfilteractivity()
    },
    filteractivitychosedate() {
      console.log('日期选择结束')
      console.log(this.filteractivity.date)
      if (this.filteractivity.selectedvalue === 0) {
        this.getfilteractivity()
      }
    },
    filteractivityselectchange(e) {
      this.filteractivity.inputuid = ''
      if (e === 0) {
        this.getfilteractivity()
      }
    },
    filteractivityinputsearch() {
      if (this.filteractivity.selectedvalue === 0) {
        this.$message.error('此统计方式禁止搜索')
      } else if (this.filteractivity.inputuid === '') {
        this.$message.warning('您需要先输入些什么')
      } else if (this.filteractivity.date.length !== 2) {
        this.$message.warning('请选择日期范围')
      } else {
        this.getfilteractivity()
      }
    },
    // 发送请求的函数
    getallactivitydata() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .post('/manager/getmainpagedata')
        .then((res) => {
          const data = res.data
          loading.close()
          console.log(data)
          if (data.code !== 0) {
            this.$message.error(data.msg)
          } else {
            console.debug(data.data)
            this.statisticdata = data.data
            this.$message({
              type: 'success',
              message: '加载成功'
            })
          }
        })
        .catch((result) => {
          this.$message.error('网络错误')
          loading.close()
        })
    },
    getfilterstudytime() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formdata = {
        starttime: this.selfstudy.date[0],
        endtime: this.selfstudy.date[1],
        statistictype: this.selfstudy.selectedvalue,
        inputvalue: this.selfstudy.inputuid
      }
      this.$http
        .post('/manager/getfilterstudytime', formdata)
        .then((res) => {
          const data = res.data
          loading.close()
          console.log(data)
          if (data.code !== 0) {
            this.$message.error(data.msg)
          } else {
            console.debug(data.data)
            const time = []
            const y = []
            for (let i = 0; i < data.data.length; i++) {
              time.push(data.data[i].year_month)
              y.push(data.data[i].total_study_time)
            }
            this.selfstudy.tabledata.xlable = time
            this.selfstudy.tabledata.y = y
            this.showselfstudytable()
            this.$message({
              type: 'success',
              message: '加载成功'
            })
          }
        })
        .catch((result) => {
          this.$message.error('网络错误')
          loading.close()
        })
    },
    getfilteractivity() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const formdata = {
        starttime: this.filteractivity.date[0],
        endtime: this.filteractivity.date[1],
        statistictype: this.filteractivity.selectedvalue,
        inputvalue: this.filteractivity.inputuid,
        usertype: this.filteractivity.radio
      }
      this.$http
        .post('/manager/getfilteractivity', formdata)
        .then((res) => {
          const data = res.data
          loading.close()
          console.debug(data)
          if (data.code !== 0) {
            this.$message.error(data.msg)
          } else {
            const type = []
            const y1 = []
            const y2 = []
            for (let i = 0; i < data.data.length; i++) {
              type.push(data.data[i].act_type)
              y1.push(data.data[i].total_num)
              y2.push(data.data[i].count)
            }
            this.filteractivity.tabledata.xlable = type
            this.filteractivity.tabledata.y1 = y1
            this.filteractivity.tabledata.y2 = y2
            this.showactivitytable()
            // this.filteractivity.tabledata
            this.$message({
              type: 'success',
              message: '加载成功'
            })
          }
        })
        .catch((result) => {
          this.$message.error('网络错误')
          loading.close()
        })
    },
    showactivitytable() {
      const chartactivityContainer = document.getElementById('filteractivity')
      const activitychart = this.$echarts.init(chartactivityContainer)
      const activityoption = {
        title: {
          text: '活动情况统计'
        },
        color: ['#5470C6', '#91CC75'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          position: 'top-right'
        },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '10%',
          top: '20%'
        },
        toolbox: {
          feature: {
            dataView: { show: true },
            saveAsImage: { show: true }
          },
          bottom: 'bottom'
        },
        legend: {
          data: ['活动数量', '参与人次'],
          orient: 'horizontal',
          right: 'top'
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              // alignWithLabel: true
            },
            // prettier-ignore
            data: this.filteractivity.tabledata.xlable,
            axisLabel: {
              fontSize: 15, // x 轴汉字的字号大小
              fontWeight: 600
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            position: 'left',
            splitLine: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#5470C6'
              }
            },
            max: Math.ceil(Math.max(...this.filteractivity.tabledata.y1) * 1.1),
            axisLabel: {
              formatter: '{value} 次'
            }
          },
          {
            type: 'value',
            position: 'right',
            alignTicks: true,
            splitLine: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#91CC75'
              }
            },
            axisLabel: {
              formatter: '{value} 人次'
            }
          }
        ],
        series: [
          {
            name: '活动数量',
            type: 'bar',
            barWidth: 35,
            data: this.filteractivity.tabledata.y1
          },
          {
            name: '参与人次',
            type: 'bar',
            barWidth: 35,
            yAxisIndex: 1,
            data: this.filteractivity.tabledata.y2
          }
        ],
        textStyle: {
          fontSize: 14 // 汉字的字号大小
        }
      }
      activitychart.setOption(activityoption)
    },
    showselfstudytable() {
      // 获取图表容器元素
      const chartstudytimeContainer = document.getElementById('selfstudytime')
      // 创建 ECharts 实例
      const studytimechart = this.$echarts.init(chartstudytimeContainer)
      // 定义图表配置
      const studytimeoption = {
        title: {
          text: '自习时长统计'
        },
        color: ['#5470C6'],

        tooltip: {
          trigger: 'axis',
          position: 'top-right'
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '10%',
          top: '20%'
        },
        toolbox: {
          feature: {
            dataView: { show: true },
            saveAsImage: { show: true }
          },
          bottom: 'bottom'
        },
        xAxis: {
          type: 'category',
          data: this.selfstudy.tabledata.xlable,
          axisLabel: {
            fontSize: 14, // x 轴汉字的字号大小
            fontWeight: 600
          },
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          position: 'left',
          // alignTicks: true,
          // splitLine: {
          //   show: false
          // },

          offset: 10,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#5470C6'
            }
          },
          axisLabel: {
            formatter: '{value} min',
            fontSize: 12
          }
        },
        series: [
          {
            name: '自习时长',
            type: 'bar',
            barWidth: 35,
            data: this.selfstudy.tabledata.y
          }
        ]
      }

      // 渲染图表
      studytimechart.setOption(studytimeoption)
    }
  }
}
</script>

<style lang="less" scoped>
.yjr-mainpagecontant {
  height: 100%;
  width: 100%;
  background-color: #f5f6fa;
  position: relative;
  padding: 20px 20px;
  .allstatistic {
    width: 100%;
    height: 210px;
    padding: 10px 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .itemcontant {
      width: calc((100% - 120px) / 5);
      height: 156px;
      opacity: 1;
      border-radius: 5px;
      background: rgba(255, 255, 255, 1);
      padding: 26px 25px;
      .itemtitle {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 1.17px;
        height: 28px;
        color: rgba(116, 121, 140, 1);
      }
      .itemcount {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .iconcontant {
          width: 53px;
          height: 53px;
          .icon {
            width: 53px;
            height: 53px;
          }
        }
        .countnum {
          font-size: 36px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 54px;
          height: 54px;
          color: rgba(40, 45, 48, 1);
          text-align: right;
        }
      }
    }
  }
  .activitycontant,
  .stydutimecontant {
    width: 100%;
    height: 340px;
    padding: 10px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ctrlbar {
      width: 100%;
      height: 70px;
      margin-bottom: 10px;
      background-color: white;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      position: relative;
      .leftcontant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 314px;
        .statucontant {
          display: flex;
          flex-direction: row;
          width: 220px;
          height: 55px;
          padding: 7px 0;
          align-items: center;
          justify-content: start;
          margin-left: 20px;
          /deep/ .el-select .el-input__inner {
            font-size: 12px !important;
            font-weight: 400;
          }
        }
        p {
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 22px;
          color: black;
          text-align: left;
          margin: 0;
        }
      }
      .rightcontant {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;

        .searchcontant {
          display: flex;
          margin-left: 20px;
        }
        .checkcontant {
          display: flex;
          margin-left: 20px;
          align-items: center;
          p {
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22px;
            color: black;
            text-align: left;
            margin: 0;
            margin-right: 5px;
            width: 80px;
          }
          /deep/ .el-radio-button__inner {
            height: 40px; /* 自定义单选按钮高度 */
            align-content: center;
          }
          /deep/ .el-input__inner {
            width: 90px;
          }
        }
        .datecontant {
          display: flex;
          margin-left: 20px;
          /deep/ .el-range-separator {
            width: 24px;
          }
        }
      }
    }
    .tablecontant {
      background-color: white;
      padding: 10px 20px 10px 20px;
    }
  }
}
</style>
